import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import {
  registerProductFeedWidget,
  unregisterProductFeedWidget,
} from "../../utils/productFeed";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import CreateFeedback from "../Feedback/CreateFeedback";
import { useMediaQuery } from "@material-ui/core";
import ThemeToggle from "../ThemeToggle";
import SpaceSelector from "../Admin/SpaceSelector";



export default observer(function Header() {
  const { sessionStore, productsStore } = useRootStore();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  //TODO: Should be done differently
  function toggleSidebar() {
    document.body.classList.toggle("aside-toggled");
  }
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    registerProductFeedWidget();

    return unregisterProductFeedWidget;
  }, []);

  const customDropdownToggle = React.forwardRef(
    (props, ref: React.Ref<HTMLAnchorElement>) => {
      let params = props as any;
      return (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="last-viewed-products-tooltip">
              {params.disabled ? "No recent products" : "Recent products"}
            </Tooltip>
          }
        >
          <a
            href="#"
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              if (params.disabled) return;
              params.onClick(e);
            }}
          >
            {params.children}
          </a>
        </OverlayTrigger>
      );
    }
  );

  return (
    <header className="topnavbar-wrapper border-top-0 border-left-0 border-right-0">
      <nav className="navbar topnavbar justify-content-between px-2 px-md-0">
        {/* <div className="navbar-header pl-md-0">
          {isDesktop && (
            <Link to="/">
              <div className="brand-logo">
                <img
                  src={brandlogo}
                  alt="Clever Compliance"
                  title="Clever Compliance"
                  className="img-fluid"
                />
              </div>
            </Link>
          )}
        </div> */}

        {sessionStore.user ? (
          <div>
            <div className="ml-4" style={{ color: "#4a5467", marginTop: 12 }}>
              <h3 className="mb-0">
                {sessionStore.user.profile.given_name +
                  " " +
                  sessionStore.user.profile.family_name}
              </h3>
              {/* <p>{sessionStore.currentSpaceName}</p> */}
              {sessionStore.hasSpaces ? (
                <Suspense fallback="Loading...">
                  <SpaceSelector />
                </Suspense>
              ) : null}
            </div>
          </div>
        ) : null}

        <ul className="navbar-nav flex-row">
          <li className="nav-item">
            <a
              href="#"
              onClick={toggleSidebar}
              className="d-flex h-100 align-items-center nav-link sidebar-toggle px-4 d-md-none"
            >
              <MenuIcon style={{ fill: "#fff" }} />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav flex-row mr-3">
          {/* {sessionStore.authenticated ? (
            <li className="nav-item d-flex align-items-center position-relative mr-5 pr-4">
              <Dropdown>
                <Dropdown.Toggle
                  disabled={productsStore.lastViewedProducts.length === 0}
                  as={customDropdownToggle}
                  id="dropdown-custom-components"
                >
                  <SettingsBackupRestoreIcon
                    className={
                      productsStore.lastViewedProducts.length > 0
                        ? "text-white"
                        : ""
                    }
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header>Recent products</Dropdown.Header>
                  {productsStore.lastViewedProducts.map((a) => (
                    <Dropdown.Item
                      onClick={() => navigate(`/products/${a.id}`)}
                      key={a.id}
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null} */}
          {/* <li
            className={`nav-item d-flex align-items-center position-relative ${
              isDesktop && "mr-5 pr-4"
            } `}
          >
            <a id="productfeed-trigger" href="#" className="text-foreground">
              What's new
            </a>
          </li> */}

          {/* <ThemeToggle /> */}
          
          <li className="nav-item d-flex align-items-center ml-2 mr-2">
            {!sessionStore.authenticated ? (
              <div className="d-flex align-items-center" style={{ gap: 8 }}>
                <button
                  type="button"
                  className="btn d-inline-flex btn-secondary text-foreground"
                  onClick={() => {
                    sessionStore.signIn();
                  }}
                >
                  Sign in
                </button>
                {isDesktop && (
                  <button
                    type="button"
                    className="btn d-inline-flex mx-2 show-on-desktop btn-primary"
                    onClick={() => {
                      sessionStore.register();
                    }}
                  >
                    Create account
                  </button>
                )}
              </div>
            ) : (
              <div className="d-flex align-items-center" style={{ gap: 8 }}>
                <button
                  style={{ width: 100 }}
                  type="button"
                  className="btn d-inline-flex btn-secondary d-flex justify-content-center"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Feedback
                </button>
                <button
                  type="button"
                  className="btn d-inline-flex btn-primary d-flex justify-content-center "
                >
                  <a href="/products" className="text-primary-foreground text-decoration-none">+ Add product</a>
                </button>
                
              </div>
            )}
          </li>
        </ul>
      </nav>
      <CreateFeedback showModal={showModal} closeModal={closeModal} />
    </header>
  );
});
