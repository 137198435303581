import React from "react";
import { useTheme } from "../../store/ThemeContext";
import logoDark from "../../assets/logo/logo-header.svg";
import logoLight from "../../assets/logo/logo-header-light.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  const { theme } = useTheme();

  return (
    <Link to="/">
      <div className="brand-logo pl-3 pt-2">
        <img
          // src={theme === "dark" ? logoDark : logoLight}
          src={logoDark}
          alt="Clever Compliance"
          title="Clever Compliance"
          className="img-fluid"
          style={{ padding: "0px 22px", margin: "14px 0px" }}
        />
      </div>
    </Link>
  );
};

export default Logo;
